<template>
  <b-form @submit.prevent="save">
    <vue-element-loading :active="loader.form"></vue-element-loading>
    <b-form-group label="Domain URL">
      <b-form-input v-model="payload.domainUrl" :disabled="!!domainUrl" trim />
    </b-form-group>

    <b-form-group label="Wordpress Username">
      <b-form-input v-model="payload.wpUser" trim />
    </b-form-group>

    <b-form-group label="Wordpress Password">
      <b-form-input v-model="payload.wpPass" trim />
    </b-form-group>

    <b-form-group label="Application Password">
      <b-form-input v-model="payload.wpAppPass" trim />
    </b-form-group>

    <div class="text-right">
      <b-button variant="success" class="mr-1" @click.stop="save">Save</b-button>
      <cancel-button @onClick="cancel"></cancel-button>
    </div>
  </b-form>
</template>

<script>
import api from "@api";

export default {
  name: "DomainDetail",
  props: {
    domainUrl: { type: String, default: null },
    agentCode: { type: String, required: true },
    nenen: { type: String, default: null },
  },
  data() {
    return {
      payload: {
        domainUrl: null,
        wpUser: null,
        wpPass: null,
        wpAppPass: null,
      },

      loader: {
        form: false,
      },
    };
  },

  async mounted() {
    if (this.domainUrl) await this.loadDomain();
  },

  methods: {
    async loadDomain() {
      this.loader.form = true;
      try {
        let result = await api.get(`domain/${this.domainUrl}`);
        this.payload = result;
      } catch (error) {
        this.showToast(`error`, error);
      } finally {
        this.loader.form = false;
      }
    },
    async save() {
      try {
        this.loader.form = true;
        if (this.domainUrl) await api.put(`domain/${this.domainUrl}`, this.payload);
        else await api.post(`domain/${this.agentCode}`, this.payload);
        this.$emit(`onSaveSuccess`);
        this.showToast(`success`, `Save Success`);
      } catch (error) {
        this.showToast(`error`, error);
      } finally {
        this.loader.form = false;
      }
    },

    cancel() {
      this.$emit(`onCancel`);
    },
  },
};
</script>
